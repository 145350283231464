import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts";
import * as ChartUtils from "../utils/chart_utils";

const SERVICE = "All Services";
const PERIOD = 12;
const HEIGHT = 210;

// Connects to data-controller="history-chart"
export default class extends Controller {
  static targets = ["chart", "legend", "service", "period", "cost", "diff", "relativeDiff"]
  static values = {
    servicesCosts: Array,
    defaultService: String,
  }

  connect() {
    this.servicesCostsValue = ChartUtils.restructureServicesCosts(this.servicesCostsValue);

    this.activeService = this.defaultServiceValue || SERVICE;
    this.legendTarget.textContent = this.activeService;
    this.#switchSelectedServiceColor(this.serviceTargets[0]);

    this.activePeriod = PERIOD;
    this.#switchSelectedPeriodColor(this.periodTargets[this.periodTargets.length - 1]);
    this.#updateServicesCostsTable();

    this.#renderChart();
  }

  updateService(event) {
    this.activeService = event.currentTarget.dataset.service;
    this.legendTarget.textContent = this.activeService;
    this.#switchSelectedServiceColor(event.currentTarget);

    this.#renderChart();
  }

  updatePeriod(event) {
    this.activePeriod = event.currentTarget.dataset.period;
    this.#switchSelectedPeriodColor(event.currentTarget);
    this.#updateServicesCostsTable();

    this.#renderChart();
  }

  #switchSelectedServiceColor(targetElement) {
    this.serviceTargets.forEach(serviceElement => {
      serviceElement.classList.toggle("bg-gray-700", serviceElement === targetElement);
    });
  }

  #switchSelectedPeriodColor(targetElement) {
    this.periodTargets.forEach(periodElement => {
      const isSelected = periodElement === targetElement;
      periodElement.classList.toggle("!border-primary-600", isSelected);
      periodElement.classList.toggle("!text-white", isSelected);
    });
  }

  #updateServicesCostsTable() {
    this.serviceTargets.forEach((serviceElement, index) => {
      const { cost, diff, relativeDiff } = this.#calculateServiceCosts(serviceElement.dataset.service);

      this.costTargets[index].textContent = ChartUtils.formatCurrency(cost);
      this.diffTargets[index].innerHTML = this.#buildDiffHTML(diff);
      this.relativeDiffTargets[index].innerHTML = this.#buildRelativeDiffHTML(relativeDiff);
    });
  }

  #calculateServiceCosts(service) {
    const recentCosts = this.servicesCostsValue.slice(-this.activePeriod, -1);
    const previousCosts = recentCosts.map(item => item.costs[service] || 0);
    const averagePreviousCost = previousCosts.reduce((sum, cost) => sum + cost, 0) / previousCosts.length;

    const currentCost = this.servicesCostsValue[this.servicesCostsValue.length - 1].costs[service];
    const costDifference = currentCost - averagePreviousCost;
    const relativeDifference = averagePreviousCost === 0 ? 0 : costDifference / averagePreviousCost;

    return { cost: currentCost, diff: costDifference, relativeDiff: relativeDifference };
  }

  #buildDiffHTML(diff) {
    const formattedDiff = ChartUtils.formatCurrency(diff);
    const className = diff >= 0 ? "text-rose-400" : "text-emerald-400";
    const sign = diff >= 0 ? "+" : "";
    return `<div class="${className}">${sign}${formattedDiff}</div>`;
  }

  #buildRelativeDiffHTML(relativeDiff) {
    const percentage = ChartUtils.formatPercentage(relativeDiff);
    const className = relativeDiff >= 0 ? "text-rose-400" : "text-emerald-400";
    const sign = relativeDiff >= 0 ? "+" : "";
    return `
      <div class="relative z-0 py-1 px-3 text-center ${className}">
        ${sign}${percentage}
        <div class="absolute top-0 left-0 bg-current rounded -z-10 opacity-15 size-full"></div>
      </div>
    `;
  }

  #renderChart() {
    this.chartTarget.innerHTML = "";
    new ApexCharts(this.chartTarget, this.#getChartOptions()).render();
  }

  #getChartOptions() {
    const { costs, categories } = this.#getFilteredData();
    return {
      series: this.#getSeriesConfig(costs),
      chart: this.#getChartConfig(),
      xaxis: this.#getXAxisConfig(categories),
      yaxis: this.#getYAxisConfig(costs),
      dataLabels: { enabled: false },
      stroke: {
        curve: "smooth",
        width: 1,
        lineCap: "round",
      },
      grid: { show: false },
      tooltip: {
        followCursor: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const cost = ChartUtils.formatCurrency(series[seriesIndex][dataPointIndex]);
          const date = w.globals.categoryLabels[dataPointIndex];
          return `
            <div class="bg-bg-dark border border-border-dark flex gap-4 px-2 py-1 text-xxs">
              <span>${cost}</span>
              <span class="text-gray">${date}</span>
            </div>
          `;
        },
      },
      colors: [ChartUtils.getTailwind("primary-600")],
      markers: {
        strokeWidth: 0,
        hover: { size: 0 },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.75,
          stops: [0, 60],
        },
      },
      legend: { show: false },
    };
  }

  #getFilteredData() {
    let costs = this.servicesCostsValue.map(item => item.costs[this.activeService] || 0);
    let categories = this.servicesCostsValue.map(item => item.date);
    if (this.activePeriod) {
      costs = costs.slice(-this.activePeriod);
      categories = categories.slice(-this.activePeriod);
    }
    return { costs, categories };
  }

  #getSeriesConfig(costs) {
    return [
      { data: costs },
    ];
  }

  #getChartConfig() {
    return {
      type: "line",
      fontFamily: "Geist Sans, Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
      height: HEIGHT,
      parentHeightOffset: 0,
      toolbar: { show: false },
      animations: { enabled: false },
      zoom: { enabled: false },
    };
  }

  #getXAxisConfig(categories) {
    return {
      categories: categories,
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: {
        width: 0.5,
        stroke: { width: 0 },
        fill: {
          type: "gradient",
          gradient: {
            colorTo: ChartUtils.getTailwind("white"),
            colorFrom: ChartUtils.getTailwind("white"),
            stops: [0, 50, 50, 100],
            opacityFrom: 0.2,
            opacityTo: 0.9,
          },
        },
      },
    };
  }

  #getYAxisConfig(costs) {
    return {
      show: true,
      tickAmount: 4,
      min: ChartUtils.getMinCost(costs),
      max: ChartUtils.getMaxCost(costs),
      opposite: true,
      labels: {
        formatter: val => ChartUtils.formatCurrency(Math.round(val)),
        style: {
          fontSize: ChartUtils.getTailwind("text-xxs"),
          colors: ChartUtils.getTailwind("white"),
        }
      }
    };
  }

}