import { Controller } from "@hotwired/stimulus"
import { computePosition, autoUpdate, offset } from "@floating-ui/dom"

// Connects to data-controller="teams-dropdown"
export default class extends Controller {
  static targets = [
    "input",
    "accountItem",
    "teamItem",
    "accountsList",
    "accountsSearchInput",
    "teamsSearchInput",
    "combobox",
    "accountsButton",
    "teamsEmpty",
    "currentTeamItem"
  ]

  triggerFromAccountsButton(event) {
    const comboboxController = this.#getComboboxController()

    if (comboboxController.openValue) return

    this.#disableComboboxClickOnWindowHandlers()
    this.#openComboboxFromAccountsButton(event)
    this.#restoreComboboxClickHandler()
  }

  onTeamItemHover({ currentTarget }) {
    this.#updateTeamVisibility(currentTarget.dataset.value)
    this.#updateTeamSelection(currentTarget)
  }

  onTeamsSearch() {
    const searchValue = this.teamsSearchInputTarget.value.toLowerCase().trim()

    if (searchValue.length == 0) {
      this.onAccountsSearch()
    } else {
      this.#getComboboxController().rubyUiComboboxContentOutlet.handleSearchInput(searchValue)

      let selectedTeam = !this.currentTeamItemTarget.classList.contains("hidden")
      let selectedTeamId = this.comboboxTarget.dataset.currentTeamId

      this.teamItemTargets.forEach((teamItem) => {
        if (
          (selectedTeam && selectedTeamId == teamItem.dataset.teamId) ||
          (!selectedTeam && !teamItem.classList.contains("hidden"))
        ) {
          this.#setTeamItemState(teamItem, true)

          selectedTeam = true
          selectedTeamId = teamItem.dataset.teamId
        } else {
          this.#setTeamItemState(teamItem, false)
        }
      })

      this.accountsListTargets.forEach((accountsList) => {
        if (accountsList.dataset.teamId == selectedTeamId) {
          accountsList.classList.remove("hidden")
        } else {
          accountsList.classList.add("hidden")
        }
      })
    }
  }

  onAccountsSearch() {
    const searchValue = this.accountsSearchInputTarget.value.toLowerCase().trim()

    const teamsWithVisibleAccountItems = new Set()

    this.#updateAccountVisibility(searchValue, teamsWithVisibleAccountItems)
    this.#updateSelectedTeam(searchValue, teamsWithVisibleAccountItems)

    if (searchValue.length == 0) {
      this.#getComboboxController().rubyUiComboboxContentOutlet.handleSearchInput(this.teamsSearchInputTarget.value)
    }
  }

  resetFromOutside(event) {
    const comboboxController = this.#getComboboxController()

    if (comboboxController.openValue) return
    if (this.comboboxTarget.contains(event.target)) return

    this.#reset()
  }

  resetFromTrigger() {
    this.#reset()
  }

  resetFloatingElementPosition() {
    const comboboxController = this.#getComboboxController()

    this.#setupFloatingUI(
      comboboxController.triggerTarget,
      comboboxController.contentTarget
    )
  }

  onCreateTeamTrigger(event) {
    try {
      this.#getComboboxController().onTriggerClick(event)
      this.#reset()
    } catch (error) {
      // Skip, don't worry.
    }

    document.getElementById("team-name-input").focus()
  }

  // Private methods

  #getComboboxController() {
    return this.application.getControllerForElementAndIdentifier(this.comboboxTarget, "ruby-ui--combobox")
  }

  #disableComboboxClickOnWindowHandlers() {
    this.dataAction = this.comboboxTarget.getAttribute("data-action")
    this.comboboxTarget.setAttribute("data-action", "")
  }

  #openComboboxFromAccountsButton(event) {
    const comboboxController = this.#getComboboxController()

    comboboxController.onTriggerClick(event)
    this.accountsSearchInputTarget.focus()

    this.#setupFloatingUI(
      this.accountsButtonTarget,
      comboboxController.contentTarget,
      { xOffset: -(56 * 4) }
    )
  }

  #restoreComboboxClickHandler() {
    setTimeout(() => {
      this.comboboxTarget.setAttribute("data-action", this.dataAction)
    }, 10)
  }

  #updateTeamVisibility(teamName) {
    this.accountsListTargets.forEach((accountsList) => {
      accountsList.classList.toggle("hidden", teamName != accountsList.dataset.teamName)
    })
  }

  #updateTeamSelection(currentTarget) {
    this.teamItemTargets.forEach((teamItem) => {
      const isSelected = teamItem == currentTarget
      this.#setTeamItemState(teamItem, isSelected)
    })
  }

  #setTeamItemState(teamItem, isSelected) {
    teamItem.setAttribute("aria-current", isSelected)
    teamItem.setAttribute("aria-selected", isSelected)

    if (!isSelected) {
      teamItem.removeAttribute("aria-current")
      teamItem.removeAttribute("aria-selected")
    }
  }

  #updateAccountVisibility(searchValue, teamsWithVisibleAccountItems) {
    this.accountItemTargets.forEach((accountItem) => {
      const isVisible = accountItem.dataset.value.toLowerCase().trim().includes(searchValue)

      accountItem.classList.toggle("hidden", !isVisible)
      accountItem.toggleAttribute("hidden", !isVisible)

      if (isVisible) {
        teamsWithVisibleAccountItems.add(accountItem.dataset.teamId)
      }
    })
  }

  #updateSelectedTeam(searchValue, teamsWithVisibleAccountItems) {
    let selectedTeam = teamsWithVisibleAccountItems.has(this.comboboxTarget.dataset.currentTeamId)
    let selectedTeamId = this.comboboxTarget.dataset.currentTeamId

    this.teamItemTargets.forEach((teamItem) => {
      const hasVisibleAccounts = teamsWithVisibleAccountItems.has(teamItem.dataset.teamId)

      if (
        (selectedTeam && selectedTeamId == teamItem.dataset.teamId) ||
        (!selectedTeam && (
          (searchValue.length > 0 && hasVisibleAccounts) ||
          (searchValue.length == 0 && teamItem.dataset.teamId == this.comboboxTarget.dataset.currentTeamId)
        ))
      ) {
        selectedTeam = true
        selectedTeamId = teamItem.dataset.teamId

        this.#setTeamItemState(teamItem, true)
      } else {
        this.#setTeamItemState(teamItem, false)
      }

      teamItem.classList.toggle("hidden", searchValue.length > 0 && !hasVisibleAccounts)
      teamItem.toggleAttribute("hidden", searchValue.length > 0 && !hasVisibleAccounts)
    })

    this.accountsListTargets.forEach((accountsList) => {
      if (accountsList.dataset.teamId == selectedTeamId && (searchValue.length == 0 || teamsWithVisibleAccountItems.has(selectedTeamId))) {
        accountsList.classList.remove("hidden")
      } else {
        accountsList.classList.add("hidden")
      }
    })

    if (!selectedTeam) {
      this.teamsEmptyTarget.classList.remove("hidden")
      this.teamsEmptyTarget.toggleAttribute("hidden", false)
    } else {
      this.teamsEmptyTarget.classList.add("hidden")
      this.teamsEmptyTarget.toggleAttribute("hidden", true)
    }
  }

  #setupFloatingUI(referenceElement, floatingElement, options = {}) {
    const comboboxController = this.#getComboboxController()

    comboboxController.cleanup = autoUpdate(
      referenceElement,
      floatingElement,
      () => {
        computePosition(referenceElement, floatingElement, {
          middleware: [offset(4)],
        }).then(({ x, y }) => {
          Object.assign(floatingElement.style, {
            left: `${x + (options.xOffset || 0)}px`,
            top: `${y}px`,
          })
        })
      }
    )
  }

  #reset() {
    const teamName = this.inputTarget.value

    this.#resetSearchInputs()
    this.#updateTeamVisibility(teamName)
  }

  #resetSearchInputs() {
    const comboboxController = this.#getComboboxController()

    this.teamsSearchInputTarget.value = ""
    comboboxController.rubyUiComboboxContentOutlet.handleSearchInput("")

    this.accountsSearchInputTarget.value = ""
    this.onAccountsSearch()
  }
}
