import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="continue-switcher"
export default class extends Controller {
  static targets = ['emailForm', 'oauthForm']

  connect() {
  }

  switchToEmail() {
    this.oauthFormTarget.classList.add('hidden')
    this.emailFormTarget.classList.remove('hidden')
  }

  switchToOauth() {
    this.oauthFormTarget.classList.remove('hidden')
    this.emailFormTarget.classList.add('hidden')
  }
}
